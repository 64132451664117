import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { getGallery } from '../Service/Api'
import LazyLoad from 'react-lazyload';
const KinderGallery = () => {

  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData1 = await getGallery();
        const topperData = topperData1.filter((item) => item.category === 'PhotoGallery' && item.school === 'Junior');

        const getcurrentData = topperData
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 6);

        setData(getcurrentData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        // Handle error (e.g., show error message)
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, []);
  const emptyArray = [
   
  ]
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
        <Slider {...settings}>

          {data?.length > 0 ? data?.filter((div) => div.category === "PhotoGallery").map((item, index) => (
            <div className="item" key={index}>
              <div className="gallery-item">
                <div className="project-thumb gal-big">
                  <Link to={`/SubGallery?id=${item._id}`}> <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} alt="Nirmala Convent School, Rajkot" className="img-fluid" /></LazyLoad> </Link>
                </div>
                <div className="project-content">
                  <h2 className="title">{item.title} </h2>
                </div>
              </div>
            </div>
          )) : (<div className='item'>

            <div className="gallery-item">
              <div className="project-thumb gal-small">
                <Link to=" "><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/gallery.png" alt="Nirmala Convent School, Rajkot" className="img-fluid" /></LazyLoad></Link>
              </div>
              <div className="project-content">
                  <h2 className="title">Title </h2>
                </div>
            </div>
          </div>
          )}

        </Slider>
      </div>
    </>
  )
}

export default KinderGallery