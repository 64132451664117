import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { getEvents } from '../Service/Api'
import LazyLoad from 'react-lazyload';
const KinderEvents = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventData1 = await getEvents();
        console.log(eventData1)
        const eventData2 = eventData1.filter(item => item.school === "Junior");
        console.log(eventData2)
        setData(eventData2);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false)
      }
    }
    fetchData();
  }, []);

  const emptyArray = [
    { title: "Event Title", description: "Stay Tunned For More Updates", date: "DD.MM", time: "00:00", venue: "School Campus" },
    { title: "Event Title", description: "Stay Tunned For More Updates", date: "DD.MM", time: "00:00", venue: "School Campus" },
    { title: "Event Title", description: "Stay Tunned For More Updates", date: "DD.MM", time: "00:00", venue: "School Campus" }

  ]
  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      <div className="row">
        <Slider {...settings}>
        {data.length > 0 ? data.map((item, index) => (
          <div className="item" key={index} >
            <div className="homeeventboximg">
            <LazyLoad> <img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid" alt="Nirmala convent School" /></LazyLoad>
              <h5>{item.title}</h5>
              <div className="date-event">
                <span><i class="bi bi-calendar-event"></i>{item.date}</span>
                <span><i className="bi bi-clock-fill"></i>{item.time} </span>
              </div>
              {item.attachments && item.attachments.length > 0 && (<span><i className="bi bi-paperclip"></i> <Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank">Attachment</Link></span>)}
              <div className="campus"><i className="bi bi-geo-alt-fill"></i>{item. venue}</div>
            </div>
          </div>
          )):emptyArray.map((index) => (
            <div className="item" key={index}>
            <div className="homeeventboximg">
            <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/event.jpg" className="img-fluid" alt="Nirmala convent School" /></LazyLoad>
              <h5>Event Title</h5>

              <div className="date-event">
                <span><i class="bi bi-calendar-event"></i>DD MM, YYYY</span>
                <span><i className="bi bi-clock-fill"></i>HH:MM </span>
              </div>
              <div className="campus"><i className="bi bi-geo-alt-fill"></i>School Campus</div>
            </div>
          </div>
          )
           
         
          )
          
          }
          {/* <div className="item">
            <div className="homeeventboximg">
              <img src="https://webapi.entab.info/api/image/NCSR/public/Images/event2.png" className="img-fluid" alt="Nirmala convent School" />
              <h5>Rakshabandhan</h5>

              <div className="date-event">
                <span><i class="bi bi-calendar-event"></i>19 Aug, 2024</span>
                <span><i className="bi bi-clock-fill"></i>8:00 AM </span>
              </div>
              <div className="campus"><i className="bi bi-geo-alt-fill"></i>School Campus</div>
            </div>
          </div>
          <div className="item">
            <div className="homeeventboximg">
              <img src="https://webapi.entab.info/api/image/NCSR/public/Images/event3.png" className="img-fluid" alt="Nirmala convent School" />
              <h5>Janmashtami</h5>

              <div className="date-event">
                <span><i class="bi bi-calendar-event"></i>26 Aug, 2024</span>
                <span><i className="bi bi-clock-fill"></i>8:00 AM </span>
              </div>
              <div className="campus"><i className="bi bi-geo-alt-fill"></i>School Campus</div>
            </div>
          </div>
          <div className="item">
            <div className="homeeventboximg">
              <img src="https://webapi.entab.info/api/image/NCSR/public/Images/event4.png" className="img-fluid" alt="Nirmala convent School" />
              <h5>Ganesh Chaturthi</h5>
              <div className="date-event">
                <span><i class="bi bi-calendar-event"></i>07 Sep, 2024</span>
                <span><i className="bi bi-clock-fill"></i>8:00 AM </span>
              </div>
              <div className="campus"><i className="bi bi-geo-alt-fill"></i>School Campus</div>
            </div>
          </div> */}
        </Slider>
        <Link to="/kinder-events"><button className='readMore'>View All</button></Link>
      </div>
    </>
  )
}

export default KinderEvents