import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getHighlight } from '../Service/Api'
import LazyLoad from 'react-lazyload';
const Highlights = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await getHighlight();
        const response = response1.filter((item) => item.school === "Senior"||item.school === "Both" )
        setData(response);
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }
    fetchData();
  }, []);

  const emptyData = [
    { id: 1, title: "Stay Tunned For More Updates" },
    { id: 2, title: "Stay Tunned For More Updates" },
    { id: 3, title: "Stay Tunned For More Updates" }

  ]
  return (
    <>
      <div className="highlights">
        <h2>Latest @NCS <span><i class="bi bi-arrow-right"></i></span></h2>
        <marquee onMouseOver={(event) => event.currentTarget.stop()} onMouseOut={(event) => event.currentTarget.start()} direction="up">
          {data && data.length > 0 ? (data.map((item, index) => (
            <div key={index}>
         
            
              <div className='d-flex'>
                <div>
                <Link to={item.url} >
                <p><span>{item.fromDate}</span></p>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                  </Link>
                </div>
                {item.attachments.length > 0 && <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank" className='attachments-highlight'> <i className="bi bi-paperclip"></i> </Link>}
                {item.url && <Link to={item.url} target="_blank" className='url-highlight' title="Click Here">Click</Link>}
               
              </div> 
            
            </div>))) : (emptyData.map((item) => (
              <div>
                <p><span>DD MM</span></p>
                <div className='d-flex'>
                  <div>
                    <h4>Highlight Title</h4>
                    <p>Stay Tuned For More Updates</p>
                  </div>
                  <Link to="/" target="_blank"> <i className="bi bi-paperclip"></i> </Link>
                </div>
              </div>
            )))}
        </marquee>
      </div>
    </>
  )
}

export default Highlights
